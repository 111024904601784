import { useState } from 'react';
import type { ReactNode } from 'react';
import { useTracking } from 'react-tracking';
import loadable from '@loadable/component';

import { Modal, Row } from '@simplywallst/ui-core';
import CrossSolid from '@simplywallst/ui-core/icons/CrossSolid';

import { PlansNative } from '@/components/OurPlans/components/PlansNative';

import { useMount } from '@simplywallst/ui-core';
import { useUserAccess } from '@/hooks/useUserAccess';

import { Content, CloseButton } from './styled';

import { useBrazeLimitHitterEvent } from '@/components/Braze/hooks/useBrazeLimitHitterEvent';
import type { EventProperties } from '@/components/Braze/hooks/useBrazeLimitHitterEvent';
import type { TrackingProps } from 'react-tracking';

const Plans = loadable(
  () => import('@/components/OurPlans/components/Plans/Plans'),
  {
    resolveComponent: (components) => components.Plans,
  }
);

const CareersUpsell = loadable(() => import('@/components/CareersUpsell'), {
  resolveComponent: (component) => component.CareersUpsell,
});
export interface UpgradePlanModalProps {
  onClose?: () => void;
  onAction?: () => void;
  children: ReactNode;
  limitHitterEventProperties?: EventProperties;
  customTrackEvent?: TrackingProps;
}

const PlanModal = ({
  onClose,
  onAction,
  limitHitterEventProperties,
  children,
  customTrackEvent = {},
}: UpgradePlanModalProps) => {
  const { data } = useUserAccess();
  const { trackEvent, Track } = useTracking({
    type: 'track',
    subject: 'page',
    modifier: 'plansModal',
    action: 'shown',
    ...customTrackEvent,
  });

  const [showCareersUpsell, setShowCareersUpsell] = useState(false);
  const { logLimitHitterEvent } = useBrazeLimitHitterEvent({ dedupe: true });

  useMount(() => {
    trackEvent();
    if (limitHitterEventProperties) {
      logLimitHitterEvent(limitHitterEventProperties);
    }
  });

  return (
    <Track>
      <Modal
        isNaked
        blurTargetId={'root'}
        removeWindowScroll={false}
        title="plans-modal"
        onClose={onClose}
        onTamper={() => setShowCareersUpsell(true)}
      >
        <Row alignContent="center">
          <Content>
            {onClose && (
              <CloseButton
                type="button"
                data-cy-id="close-plans-modal"
                onClick={() => {
                  onAction && onAction();
                  onClose();
                }}
              >
                <CrossSolid />
              </CloseButton>
            )}
            {children}
            {RUNTIME_ENV === 'native' ? (
              <PlansNative />
            ) : (
              <Plans
                darkBackground
                hideUserMessage
                hideFreePlan={data.planType === 'premium'}
                onClose={onClose}
                onAction={onAction}
              />
            )}
          </Content>
        </Row>
      </Modal>
      {showCareersUpsell && typeof onClose === 'undefined' ? (
        <CareersUpsell />
      ) : null}
    </Track>
  );
};

export default PlanModal;
