import { useCallback, useEffect } from 'react';
import type { ReactNode } from 'react';
import { Content } from './styled';
import { Row } from '@simplywallst/ui-core';
import { useUserAccess } from '@/hooks/useUserAccess';
import { useTracking } from 'react-tracking';
import { PlansNative } from '@/components/OurPlans/components/PlansNative';
import Observer from '@researchgate/react-intersection-observer';
import loadable from '@loadable/component';
import { useBrazeLimitHitterEvent } from '@/components/Braze/hooks/useBrazeLimitHitterEvent';
import type { EventProperties } from '@/components/Braze/hooks/useBrazeLimitHitterEvent';
const Plans = loadable(
  () => import('@/components/OurPlans/components/Plans/Plans'),
  {
    resolveComponent: (components) => components.Plans,
  }
);

interface Props {
  children?: ReactNode;
  limitHitterEventProperties?: EventProperties;
}

const PlansInline = ({ children, limitHitterEventProperties }: Props) => {
  const { trackEvent } = useTracking();
  const { logLimitHitterEvent } = useBrazeLimitHitterEvent({ dedupe: true });

  useEffect(() => {
    trackEvent({
      modifier: 'plansModal',
      action: 'shown',
    });
  }, [trackEvent]);

  const logLimitHitter = useCallback(
    ({ isIntersecting }) => {
      const shouldLogLimitHitter = isIntersecting && limitHitterEventProperties;
      if (shouldLogLimitHitter) {
        logLimitHitterEvent(limitHitterEventProperties);
      }
    },
    [limitHitterEventProperties, logLimitHitterEvent]
  );

  const { data } = useUserAccess();
  return (
    <Observer onChange={logLimitHitter}>
      <Row alignContent="center">
        <Content data-cy-id="plans-inline">
          {children}
          {RUNTIME_ENV === 'native' ? (
            <PlansNative />
          ) : (
            <Plans
              darkBackground
              hideUserMessage
              hideFreePlan={data.planType === 'premium'}
            />
          )}
        </Content>
      </Row>
    </Observer>
  );
};
export default PlansInline;
