import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export const Content = styled('div')`
  background-color: ${({ theme }) => theme.color.spaceGray};
  text-align: center;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  max-width: 1200px;
  position: relative;
  padding: 0 ${({ theme }) => `${theme.x2spacePx}px ${theme.x8spacePx}px`};
  ${media.lgUp`
    padding: 0 ${({ theme }) => theme.x4spacePx}px;
  `}
  > p {
    ${({ theme }) => theme.typography.baseStrong}
    padding: ${({ theme }) => theme.x5spacePx}px 0 0;
    margin: 0;
    color: ${({ theme }) => theme.color.warning};
  }
  > h2 {
    margin-top: ${({ theme }) => theme.x1spacePx}px;
    color: ${({ theme }) => theme.color.white};
    font-size: 3.2rem;
    font-weight: bold;
  }
`;

export const CloseButton = styled('button')`
  position: absolute;
  right: ${({ theme }) => theme.x4spacePx}px;
  top: ${({ theme }) => theme.x2spacePx}px;
  ${media.mdUp`
    right: ${({ theme }) => theme.x4spacePx}px;
    top: ${({ theme }) => theme.x3spacePx}px;
  `}
  svg {
    fill: ${({ theme }) => theme.color.whiteRGBA(0.6)};
  }
`;
