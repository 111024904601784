import { useCallback, useRef } from 'react';
import { useBraze } from '@/components/Braze';

type PortfolioEventProperties = {
  trigger:
    | 'portfolio'
    | 'portfolio-upgrade-cta'
    | 'portfolio-creation'
    | 'portfolio-holdings';
};

type ScreenerEventProperties = {
  trigger: 'screener';
  screener_name: string;
};

type CompanyReportEventProperties = {
  trigger: 'company_report';
  company_tickers: string;
};

export type EventProperties =
  | ScreenerEventProperties
  | PortfolioEventProperties
  | CompanyReportEventProperties;

const getEventProperties = (eventProperties: EventProperties) => {
  const { trigger } = eventProperties;
  switch (trigger) {
    case 'portfolio':
    case 'portfolio-upgrade-cta':
    case 'portfolio-creation':
    case 'portfolio-holdings':
      return {
        trigger,
      };
    case 'screener':
      return {
        trigger,
        screener_name: eventProperties.screener_name,
      };
    case 'company_report':
      return {
        trigger,
        company_tickers: eventProperties.company_tickers,
      };
  }
};

type UseLimitHitterOptions = {
  dedupe?: boolean;
};

export const useBrazeLimitHitterEvent = ({
  dedupe = false,
}: UseLimitHitterOptions = {}) => {
  const { logCustomEvent } = useBraze();
  const ref = useRef(new Set());

  const logLimitHitterEvent = useCallback(
    (eventProperties: EventProperties) => {
      const properties = getEventProperties(eventProperties);
      const shouldLog = !dedupe || !ref.current.has(JSON.stringify(properties));
      if (shouldLog) {
        ref.current.add(JSON.stringify(properties));
        logCustomEvent('plan_limit_seen', properties);
      }
    },
    [dedupe, logCustomEvent]
  );

  return { logLimitHitterEvent };
};
